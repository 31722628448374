import { Button } from '@design-system';
import { useAnalyticsEmitter } from '@utils/analytics/emitter';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useFlow } from '../../providers';
import { ProgressIndicator } from '../Containers/Dialog/Step/Header';

interface Props {
  onChange?: (input: Record<string, string>) => void;
  isStarted?: boolean;
}

export const Intro = ({ isStarted, onChange }: Props) => {
  const { t } = useTranslation('onboarding');
  const { closeFlow, goNextStep } = useFlow();
  const [emit] = useAnalyticsEmitter();

  const handleNext = () => {
    if (onChange) onChange({});
    emit('', 'clicked', { progress: 'submit' });
    goNextStep();
  };

  const handleClose = () => {
    emit('remind me later', 'clicked');
    closeFlow();
  };

  return (
    <>
      <img alt={t('intro.title')} src="/images/onboarding-intro.jpg" />
      <div className="flex flex-col gap-6 p-6 text-center">
        <div className="flex flex-col justify-center gap-3 px-3">
          <ProgressIndicator currentStepIndex={0} stepCount={6} />
          <h2 className="text-md font-bold md:text-lg">{t('intro.title')}</h2>
          <p>
            {!isStarted
              ? t('intro.description.new')
              : t('intro.description.started')}
          </p>
        </div>
        <Button
          fullWidth
          label={t('intro.cta')}
          onClick={handleNext}
          size="lg"
          type="button"
          variant="primary"
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-3 bg-neutral-1 px-6 py-5">
        <div>
          <button className="hyperlink" onClick={handleClose} type="button">
            {t('intro.help')}
          </button>
        </div>
      </div>
    </>
  );
};
